/* eslint-disable no-unused-vars */
import {
  CANCEL_BOLETO_TRANSACTION_ERROR,
  CANCEL_BOLETO_TRANSACTION_REQUEST,
  CANCEL_BOLETO_TRANSACTION_SUCCESS,
  CAPTURE_TRANSACTION_ERROR,
  CAPTURE_TRANSACTION_REQUEST,
  CAPTURE_TRANSACTION_SUCCESS,
  GET_RECEIVABLES_ERROR,
  GET_RECEIVABLES_REQUEST,
  GET_RECEIVABLES_SUCCESS,
  GET_TRANSACTION_BY_ID_ERROR,
  GET_TRANSACTION_BY_ID_REQUEST,
  GET_TRANSACTION_BY_ID_SUCCESS,
  GET_TRANSACTION_CANCELLED_LETTER_ERROR,
  GET_TRANSACTION_CANCELLED_LETTER_REQUEST,
  GET_TRANSACTION_CANCELLED_LETTER_SUCCESS,
  LIST_TRANSACTIONS_ERROR,
  LIST_TRANSACTIONS_REQUEST,
  LIST_TRANSACTIONS_SUCCESS,
  RESET_TRANSACTION,
  RESET_TRANSACTION_ACTIONS,
  VOID_TRANSACTION_ERROR,
  VOID_TRANSACTION_REQUEST,
  VOID_TRANSACTION_SUCCESS
} from '../Constants/ActionTypes'

import cancelledLetterRequestAPI from 'src/Configs/cancelledLetterRequestAPI'
import createToast from 'src/Utils/createToast'
import moment from 'moment'
import queryRequestsAPI from 'src/Configs/queryRequestsAPI'
import requestApi from '../Configs/request'
import dashBffRequestAPI from '../Configs/dashBFFRequest'
import takeError from 'src/Errors/takeError'
import transactionErrors from 'src/Errors/transactionErrors'
import uriRequests from '../Configs/uriRequests'
import {
  isEligibleToCancelledLetter,
  getTransactionStatus
} from '../Utils/saleUtils'
import appConfig from '../Configs/appConfig'

const SOURCE_FIELDS =
  'id,amount,created_at,status,individual.first_name,individual.last_name,' +
  'individual.tax_payer_identification,business.business_name,business.ein,payment_type,number_of_installments,' +
  'id_reference,card.id,card.created_at,card.brand,boleto.id,boleto.created_at,pix.id,pix.created_at,entry_mode,confirmed'

const checkSandbox = (marketplace) => {
  if (
    marketplace?.details?.type === 'business_test' ||
    marketplace?.rootDetails?.type === 'business_test'
  ) {
    return {
      apiVersion: 1,
      mountedParams: {
        sort: 'time-descending',
        payment_type: 'credit,debit,boleto,pix,wallet,nupay',
        status: undefined,
        offset: 0
      },
      isSandbox: true
    }
  }
  return {
    apiVersion: 1,
    mountedParams: {
      sort: 'created_at:desc',
      payment_type: 'credit,debit,boleto,voucher,pix,wallet,nupay,bolepix'
    },
    isSandbox: false
  }
}

export const getTransactions = (filters = {}) => {
  const request = () => ({ type: LIST_TRANSACTIONS_REQUEST })
  const success = (transactions, totalItems, totalPages, limit) => ({
    type: LIST_TRANSACTIONS_SUCCESS,
    transactions,
    totalItems,
    totalPages,
    limit
  })
  const failure = (error) => ({ type: LIST_TRANSACTIONS_ERROR, error })

  return (dispatch, getState) => {
    const { marketplace: rootMarketplace } = getState()

    const { mountedParams, isSandbox } = checkSandbox(rootMarketplace)

    const urlRequest = uriRequests.getMarketplaceTransactions

    if (filters.date_range && typeof filters.date_range === 'string') {
      filters.date_range = JSON.parse(filters.date_range)
    }

    if (filters.amount_range && typeof filters.amount_range === 'string') {
      filters.amount_range = JSON.parse(filters.amount_range)
    }

    if (filters.sort) {
      filters.sort = filters.sort.replace('date:', 'created_at:')
    }

    if (filters.entry_mode) {
      filters.entry_mode = filters.entry_mode.replaceAll('|', ',')
    }

    if (filters.sort) {
      if (isSandbox) {
        filters.sort =
          filters.sort === 'created_at:desc'
            ? 'time-descending'
            : 'time-ascending'
      } else {
        filters.sort = filters.sort.replace('date:', 'created_at:')
      }
    }

    const params = {
      offset: 0,
      limit: 100,
      collapse: false,
      fields: SOURCE_FIELDS,
      date_range: {
        gte: moment().startOf('day').toDate(),
        lte: moment().endOf('day').toDate()
      },
      ...mountedParams,
      ...filters,
      isSandbox: true
    }

    if (filters.search_id) {
      delete params.date_range
    }

    dispatch(request())
    const clientRequest = isSandbox ? requestApi : queryRequestsAPI

    return clientRequest
      .get(urlRequest, { params })
      .then((response) => {
        const { data } = response

        if (data) {
          let { items: transactions, total, limit } = data

          transactions = transactions.map((transaction) => {
            const isConfirmed = getTransactionStatus(transaction)
            return {
              ...transaction,
              status: isConfirmed,
              date: {
                day: moment(transaction.created_at).format('DD/MM/YYYY'),
                hour: moment(transaction.created_at).format('HH:mm:ss')
              }
            }
          })
          const totalPages = Math.ceil(total / limit) || 1
          dispatch(success(transactions, total.toString(), totalPages, limit))
        }
      })
      .catch((error) => {
        const { response } = error

        if (response.status === 404) {
          return dispatch(success([], 0, 0, params.limit))
        }

        if (response.status === 504 || response.status === 500) {
          return dispatch(failure(takeError(error, transactionErrors)))
        }
        dispatch(failure(takeError(error, transactionErrors)))
      })
  }
}

export const getLastTransactions = () => {
  const request = () => ({ type: LIST_TRANSACTIONS_REQUEST })
  const success = (transactions, totalItems, totalPages, limit) => ({
    type: LIST_TRANSACTIONS_SUCCESS,
    transactions,
    totalItems,
    totalPages,
    limit
  })
  const failure = (error) => ({ type: LIST_TRANSACTIONS_ERROR, error })

  return (dispatch, getState) => {
    const urlRequest = uriRequests.getMarketplaceTransactions

    const { marketplace: rootMarketplace } = getState()

    const { isSandbox } = checkSandbox(rootMarketplace)

    const params = {
      offset: 0,
      limit: 3,
      collapse: false,
      fields: SOURCE_FIELDS,
      sort: isSandbox ? 'time-descending' : 'created_at:desc'
    }

    dispatch(request())

    const clientRequest = isSandbox ? requestApi : queryRequestsAPI

    return clientRequest
      .get(urlRequest, { params })
      .then((response) => {
        const { data } = response

        if (data) {
          let { items: transactions, total } = data
          transactions = transactions.map((transaction) => {
            const isConfirmed = getTransactionStatus(transaction)
            return {
              ...transaction,
              status: isConfirmed,
              date: {
                day: moment(transaction.created_at).format('DD/MM/YYYY'),
                hour: moment(transaction.created_at).format('HH:mm:ss')
              }
            }
          })
          const totalPages = Math.ceil(total / 100) || 1
          dispatch(success(transactions, total.toString(), totalPages, 100))
        }
      })
      .catch((error) => {
        const { response } = error

        if (response.status === 404) {
          return dispatch(success([]))
        }

        if (response.status === 504 || response.status === 500) {
          return dispatch(failure(takeError(error, transactionErrors)))
        }

        dispatch(failure(takeError(error, transactionErrors)))
      })
  }
}

export const getTransactionSearchById = (transactionId) => {
  const request = () => ({ type: LIST_TRANSACTIONS_REQUEST })
  const success = (transactions, totalItems, totalPages, limit) => ({
    type: LIST_TRANSACTIONS_SUCCESS,
    transactions,
    totalItems,
    totalPages,
    limit
  })
  const failure = (error) => ({ type: LIST_TRANSACTIONS_ERROR, error })

  return (dispatch, getState) => {
    const {
      marketplace: rootMarketplace,
      transactions: { transactionsPageSize: limit }
    } = getState()

    const { isSandbox } = checkSandbox(rootMarketplace)

    const urlRequest = uriRequests.getTransaction.replace(
      '{transactionId}',
      transactionId
    )

    dispatch(request())

    const clientRequest = isSandbox ? requestApi : queryRequestsAPI

    return clientRequest
      .get(urlRequest)
      .then((response) => {
        const { data } = response
        if (data) {
          const transactions = [data]
          const total = 1
          const totalPages = 1
          dispatch(success(transactions, total.toString(), totalPages, limit))
        }
      })
      .catch((error) => {
        const { response } = error

        if (response.status === 404) {
          return dispatch(success([], '0', 0, 0))
        }

        if (response.status === 504 || response.status === 500) {
          return dispatch(failure(takeError(error, transactionErrors)))
        }
        dispatch(failure(takeError(error, transactionErrors)))
      })
  }
}

export const getTransactionById = (id) => {
  const request = () => ({ type: GET_TRANSACTION_BY_ID_REQUEST })
  const success = (transaction) => ({
    type: GET_TRANSACTION_BY_ID_SUCCESS,
    transaction
  })
  const failure = (error) => ({ type: GET_TRANSACTION_BY_ID_ERROR, error })

  return (dispatch) => {
    const urlRequest = uriRequests.getTransaction.replace('{transactionId}', id)

    dispatch(request())
    return requestApi
      .get(urlRequest)
      .then(({ data: transaction }) => {
        if (transaction) {
          const isConfirmed = getTransactionStatus(transaction)
          transaction.status = isConfirmed

          return queryRequestsAPI
            .get(
              uriRequests.getSellerDetails.replace(
                '{sellerId}',
                transaction.on_behalf_of
              )
            )
            .then(({ data: seller }) => {
              transaction[seller.type] = seller
              dispatch(success(transaction))
            })
            .catch((error) => {
              console.log(error)
              dispatch(success(transaction))
            })
        }
      })
      .catch((error) => {
        dispatch(failure(takeError(error, transactionErrors)))
      })
  }
}

export const getReceivables = (id) => {
  const request = () => {
    return { type: GET_RECEIVABLES_REQUEST }
  }
  const success = (receivables, totalItems) => {
    return { type: GET_RECEIVABLES_SUCCESS, receivables, totalItems }
  }
  const failure = (error) => {
    return { type: GET_RECEIVABLES_ERROR, error }
  }

  return (dispatch) => {
    const urlRequest = uriRequests.getReceivables.replace('{transactionId}', id)

    dispatch(request())

    return requestApi
      .get(urlRequest)
      .then(({ data }) => {
        if (data) {
          const { items, query_count: queryCount } = data

          dispatch(success(items, queryCount))
        }
      })
      .catch((error) => {
        dispatch(failure(takeError(error, transactionErrors)))
      })
  }
}

export const createTransaction = async (data) => {
  const urlRequest = uriRequests.createTransaction

  return requestApi
    .post(urlRequest, { ...data }, { bypassSellerContext: true })
    .then((response) => {
      if (response.data) {
        return response.data
      }
    })
    .catch((e) => {
      return null
    })
}

export const voidTransaction = (transactionId, data) => {
  const request = () => ({ type: VOID_TRANSACTION_REQUEST })
  const success = () => ({
    type: VOID_TRANSACTION_SUCCESS,
    amount: data.amount
  })
  const failure = (error) => ({ type: VOID_TRANSACTION_ERROR, error })

  return (dispatch) => {
    dispatch(request())

    const urlRequest = uriRequests.voidTransaction.replace(
      '{transactionId}',
      transactionId
    )

    return dashBffRequestAPI
      .post(urlRequest, data)
      .then(() => {
        dispatch(success())
      })
      .catch((e) => {
        const error = takeError(e, transactionErrors)
        if (error.status === 500) {
          dispatch(
            failure({
              status: error.status,
              message: 'Não foi possível estornar a venda',
              error: e
            })
          )
        } else {
          dispatch(failure(error))
        }
      })
  }
}

export const cancelBoletoTransaction = (transactionId) => {
  const request = () => ({ type: CANCEL_BOLETO_TRANSACTION_REQUEST })
  const success = () => ({ type: CANCEL_BOLETO_TRANSACTION_SUCCESS })
  const failure = (error) => ({ type: CANCEL_BOLETO_TRANSACTION_ERROR, error })

  return (dispatch, getState) => {
    const {
      marketplace: {
        details: { id: marketplaceId }
      }
    } = getState()

    dispatch(request())

    const urlRequest = uriRequests.cancelBoleto
      .replace('{marketplaceId}', marketplaceId)
      .replace('{transactionId}', transactionId)

    return requestApi
      .post(
        `${appConfig.hostApi}/bff-others/${urlRequest}`,
        {},
        {
          ignoreSellerAndMarketplaceContext: true,
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        if (
          response.data.message ===
          'Cancellation already requested successfully'
        ) {
          return dispatch(
            failure({
              type: 'info',
              message:
                'Já existe uma solicitação de cancelamento em andamento para esta transação'
            })
          )
        }

        dispatch(success())
      })
      .catch((e) => {
        const error = takeError(e, transactionErrors)
        if (error.status === 500) {
          dispatch(
            failure({
              status: error.status,
              message: 'Não foi possível cancelar a venda',
              error: e
            })
          )
        } else {
          dispatch(failure(error))
        }
      })
  }
}

export const captureTransaction = (transactionId, data) => {
  const request = () => ({ type: CAPTURE_TRANSACTION_REQUEST })
  const success = () => ({
    type: CAPTURE_TRANSACTION_SUCCESS,
    amount: data.amount
  })
  const failure = (error) => ({ type: CAPTURE_TRANSACTION_ERROR, error })

  return (dispatch) => {
    dispatch(request())

    const urlRequest = `${uriRequests.captureTransaction}`.replace(
      '{transactionId}',
      transactionId
    )

    return requestApi
      .post(urlRequest, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(() => {
        dispatch(success())
      })
      .catch((e) => {
        const error = takeError(e, transactionErrors)
        if (error.status === 500) {
          dispatch(
            failure({
              status: error.status,
              message: 'Não foi possível autorizar a venda',
              error: e
            })
          )
        } else {
          dispatch(failure(error))
        }
      })
  }
}

const checkIfTransactionIsAlreadyCancelledOnElastic = async (id) => {
  const urlRequest = uriRequests.getMarketplaceTransactions

  const transaction = await queryRequestsAPI.get(urlRequest, {
    params: { search_id: id }
  })

  if (transaction?.data?.items?.length) {
    return isEligibleToCancelledLetter(transaction.data.items[0].history)
  }

  return false
}

export const getTransactionCancelledLetter = (transactionId) => {
  const request = () => ({ type: GET_TRANSACTION_CANCELLED_LETTER_REQUEST })
  const success = () => ({ type: GET_TRANSACTION_CANCELLED_LETTER_SUCCESS })
  const failure = (error) => ({
    type: GET_TRANSACTION_CANCELLED_LETTER_ERROR,
    error
  })

  return async (dispatch) => {
    const alreadyCancelled =
      await checkIfTransactionIsAlreadyCancelledOnElastic(transactionId)

    if (!alreadyCancelled) {
      createToast(
        'warning',
        'A carta de cancelamento ainda está sendo processada.<br /><strong>Tente novamente em instantes.</strong>',
        transactionId
      )
      return
    }

    const uriRequest = uriRequests.getTransactionCancelledLetter.replace(
      '{transactionId}',
      transactionId
    )

    dispatch(request())

    return cancelledLetterRequestAPI
      .get(uriRequest)
      .then((res) => {
        dispatch(success())

        const cancelledLetterUrl = res.data.url
        const link = document.createElement('a')
        link.href = cancelledLetterUrl
        link.setAttribute('download', transactionId)
        document.body.appendChild(link)
        link.click()
        link.remove()
      })
      .catch((error) => {
        const e = takeError(error, transactionErrors)
        if (e.status >= 500) {
          createToast(
            'error',
            'Não conseguimos emitir a carta de cancelamento. Tente novamente.',
            transactionId,
            e.status
          )
        } else {
          createToast('error', e.message, transactionId, e.status)
        }
        dispatch(failure(e))
      })
  }
}

export const resetTransaction = () => {
  const reset = () => ({ type: RESET_TRANSACTION })

  return async (dispatch) => {
    dispatch(reset())
  }
}

export const resetTransactionActions = () => {
  const reset = () => ({ type: RESET_TRANSACTION_ACTIONS })

  return async (dispatch) => {
    dispatch(reset())
  }
}
