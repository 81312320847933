import { PAIR_POS_ERROR, PAIR_POS_REQUEST, PAIR_POS_SUCCESS, RESET_POS } from '../Constants/ActionTypes'
import requestApi from '../Configs/request'
import uriRequests from '../Configs/uriRequests'
import appConfig from '../Configs/appConfig'
import takeError from 'src/Errors/takeError'
import posErrors from 'src/Errors/posErrors'

// eslint-disable-next-line no-unused-vars
export const pairPOS = (marketplaceId, sellerId, token, emvSerialNumber) => {
  const request = () => ({ type: PAIR_POS_REQUEST })
  const success = () => ({ type: PAIR_POS_SUCCESS })
  const failure = (error) => ({ type: PAIR_POS_ERROR, error })

  return (dispatch) => {
    dispatch(request())

    const isStaging = appConfig.isStaging
    const requestPairPos = requestApi.post(uriRequests.pairPos.replace('{sellerId}', sellerId), {
      isStaging,
      marketplaceId,
      sellerId,
      token
    })

    return requestPairPos
      .then(() => {
        dispatch(success())
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          dispatch(failure({ status: 400, message: 'Você digitou um token válido?' }))
        } else {
          dispatch(failure(takeError(error, posErrors)))
        }
      })
  }
}

export const resetSearchPOSSeller = () => (dispatch) => dispatch({ type: RESET_POS })
