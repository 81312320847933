import dashboardApiRequest from 'src/Configs/dashboardApiRequest'
import queryRequestsAPI from 'src/Configs/queryRequestsAPI'
import dashBFFRequest from '../Configs/dashBFFRequest'
import uriRequests from '../Configs/uriRequests'
import {
  FAVORITE_SELLERS_ERROR,
  FAVORITE_SELLERS_REQUEST,
  FAVORITE_SELLERS_SUCCESS,
  GET_SELLERS_ERROR,
  GET_SELLERS_REQUEST,
  GET_SELLERS_SEARCH_ERROR,
  GET_SELLERS_SEARCH_REQUEST,
  GET_SELLERS_SEARCH_SUCCESS,
  GET_SELLERS_SUCCESS,
  RESET_SELLERS,
  SELLER_DETAILS_ERROR,
  SELLER_DETAILS_REQUEST,
  SELLER_DETAILS_SUCCESS,
  UPDATE_FAVORITE_SELLERS
} from '../Constants/ActionTypes'
import sellersErrors from '../Errors/sellersErrors'
import takeError from '../Errors/takeError'
import { getFavoritesSellers } from './favorites'

const SOURCE_FIELDS =
  'id,created_at,status,type,statement_descriptor,first_name,last_name,business_name,ein,taxpayer_id,business_email,email'

const defaultParams = {
  sort: 'created_at:desc',
  limit: 100,
  collapse: true
}

const defaultParamsSelect = {
  sort: 'created_at:desc',
  limit: 100,
  collapse: true,
  not_status: 'deleted'
}

const checkSandbox = (marketplace) => {
  if (
    marketplace?.details?.type === 'business_test' ||
    marketplace?.rootDetails?.type === 'business_test'
  ) {
    return {
      isSandbox: true,
      apiVersion: 1,
      mountedParams: {
        sort: 'time-descending',
        status: null
      }
    }
  }
  return {
    isSandbox: false,
    apiVersion: 1,
    mountedParams: {}
  }
}

export const resetSellers = () => {
  return (dispatch) => dispatch({ type: RESET_SELLERS })
}

export const getSellers = (filters = {}) => {
  const request = () => ({ type: GET_SELLERS_REQUEST })
  const success = (data, totalItems, totalPages, limit) => ({
    type: GET_SELLERS_SUCCESS,
    data,
    totalItems,
    totalPages,
    limit
  })
  const failure = (error) => ({ type: GET_SELLERS_ERROR, error })

  return (dispatch, getState) => {
    const { marketplace: rootMarketplace } = getState()

    const { mountedParams, isSandbox } = checkSandbox(rootMarketplace)

    const urlRequest = uriRequests.getSellers

    if (filters.date_range && typeof filters.date_range === 'string') {
      filters.date_range = JSON.parse(filters.date_range)
    }

    if (filters.sort) {
      if (isSandbox) {
        filters.sort =
          filters.sort === 'created_at:desc'
            ? 'time-descending'
            : 'time-ascending'
      }
    }

    const params = {
      fields: SOURCE_FIELDS,
      ...defaultParams,
      sort: mountedParams.sort || defaultParams.sort,
      status: mountedParams.status,
      ...filters
    }

    if (filters.taxpayer_id || filters.ein || filters.name) {
      delete params.date_range
    }

    dispatch(request())

    const clientRequest = isSandbox ? dashBFFRequest : queryRequestsAPI

    clientRequest
      .get(urlRequest, { params })
      .then((response) => {
        const { data } = response

        if (data) {
          const { items: sellers, total, limit } = data
          const totalPages = Math.ceil(total / limit) || 1
          dispatch(success(sellers, total, totalPages, limit))
        }
      })
      .catch((error) => {
        const { response } = error
        if (response.status === 404) {
          return dispatch(success([], 0, 0, 0))
        }

        if (response.status === 504 || response.status === 500) {
          return dispatch(failure(takeError(error, sellersErrors)))
        }
        dispatch(failure(takeError(error, sellersErrors)))
      })
  }
}

export const searchSeller = (filters = {}) => {
  const request = () => ({ type: GET_SELLERS_REQUEST })
  const success = (data) => ({ type: GET_SELLERS_SUCCESS, data, totalPages: 1 })
  const failure = (error) => ({ type: GET_SELLERS_ERROR, error })

  return (dispatch, getState) => {
    const { marketplace: rootMarketplace } = getState()

    const { mountedParams, isSandbox } = checkSandbox(rootMarketplace)

    if (!filters.name) {
      delete filters['name']
    }

    const params = {
      fields: SOURCE_FIELDS,
      ...defaultParams,
      ...filters,
      sort: mountedParams.sort || defaultParams.sort,
      status: mountedParams.status
    }

    const clientRequest = isSandbox ? dashBFFRequest : queryRequestsAPI

    const requestSearchSeller = clientRequest.get(uriRequests.searchSeller, {
      params
    })

    dispatch(request())

    requestSearchSeller
      .then((response) => {
        return dispatch(success([response.data]))
      })
      .catch((error) => {
        const { response } = error
        if (response.status === 404) {
          return dispatch(success([]))
        }

        if (response.status === 504 || response.status === 500) {
          return dispatch(failure(takeError(error, sellersErrors)))
        }
        dispatch(failure(takeError(error, sellersErrors)))
      })
  }
}

export const getFavorites = () => {
  const request = () => ({ type: FAVORITE_SELLERS_REQUEST })
  const success = (data) => ({ type: FAVORITE_SELLERS_SUCCESS, data })
  const failure = (error) => ({ type: FAVORITE_SELLERS_ERROR, error })

  return async (dispatch, getState) => {
    const {
      user: { id: userId }
    } = getState()

    const uri = uriRequests.favoriteSellers.replace('{userId}', userId)

    dispatch(request())
    try {
      const favoriteSellersIdsResponse = await dashboardApiRequest.get(uri)

      if (
        favoriteSellersIdsResponse &&
        favoriteSellersIdsResponse.data &&
        favoriteSellersIdsResponse.data.sellers.length === 0
      ) {
        dispatch(success(favoriteSellersIdsResponse.data.sellers))
      }

      if (
        favoriteSellersIdsResponse &&
        favoriteSellersIdsResponse.data &&
        favoriteSellersIdsResponse.data.sellers.length
      ) {
        // TODO implementar chamada do api query passando os ids dos favoritos
        let favoriteSellersResponse = await queryRequestsAPI.get(
          uriRequests.getSellers,
          {
            params: {
              seller_ids: favoriteSellersIdsResponse.data.sellers.toString()
            }
          }
        )

        let favoriteSellers = []

        if (
          favoriteSellersResponse &&
          favoriteSellersResponse.data &&
          favoriteSellersResponse.data.items.length
        ) {
          favoriteSellers = favoriteSellersResponse.data.items.map(
            (seller) => ({
              ...seller,
              isFavorite: true
            })
          )
        }

        dispatch(success(favoriteSellers))
      }
    } catch (responseError) {
      const { response: favoriteSellersResponse } = responseError
      const error = takeError(responseError, sellersErrors)

      if (favoriteSellersResponse.status === 404) {
        return dispatch(success([]))
      }

      if (
        favoriteSellersResponse.status === 504 ||
        favoriteSellersResponse.status === 500
      ) {
        return dispatch(failure(error))
      }
      dispatch(failure(error))
    }
  }
}

export const setFavorite = (seller) => {
  return (dispatch, getState) => {
    const {
      user: { id: userId },
      sellers: { favoriteSellers }
    } = getState()

    const uri = uriRequests.favoriteSellerId
      .replace('{userId}', userId)
      .replace('{sellerId}', seller.id)

    try {
      const newFavorites = [
        ...favoriteSellers,
        {
          ...seller,
          isFavorite: true
        }
      ]

      dispatch({
        type: UPDATE_FAVORITE_SELLERS,
        data: newFavorites
      })

      dashboardApiRequest.put(uri)
    } catch (e) {
      // Do nothing
    }
  }
}

export const deleteFavorite = (seller) => {
  return (dispatch, getState) => {
    const {
      user: { id: userId },
      sellers: { favoriteSellers }
    } = getState()

    const uri = uriRequests.favoriteSellerId
      .replace('{userId}', userId)
      .replace('{sellerId}', seller.id)

    try {
      const newFavorites = favoriteSellers.filter(
        (item) => item.id !== seller.id
      )

      dispatch({
        type: UPDATE_FAVORITE_SELLERS,
        data: newFavorites
      })

      dashboardApiRequest.delete(uri)
    } catch (e) {
      // Do nothing
    }
  }
}

// ACTION DUPLICADA PARA ATENDER A PESQUISA NO SELECT DE SELLERS E NÃO INFLUENCIAR NA LISTA DE SELLERS
export const getSellersSelect = (filters = {}) => {
  const request = () => ({ type: GET_SELLERS_SEARCH_REQUEST, data: [] })
  const success = (data, sellersSearchCount, totalPages, limit) => ({
    type: GET_SELLERS_SEARCH_SUCCESS,
    data,
    sellersSearchCount,
    totalPages,
    limit
  })
  const failure = (error) => ({ type: GET_SELLERS_SEARCH_ERROR, error })

  return async (dispatch, getState) => {
    const {
      sellers: { sellersSearch },
      marketplace: rootMarketplace
    } = getState()

    const { mountedParams, isSandbox } = checkSandbox(rootMarketplace)

    const urlRequest = uriRequests.getSellers

    if (filters.date_range && typeof filters.date_range === 'string') {
      filters.date_range = JSON.parse(filters.date_range)
    }

    if (!filters.name) {
      delete filters['name']
    }

    const params = {
      ...defaultParamsSelect,
      ...filters,
      sort: mountedParams.sort || 'name:asc'
    }

    dispatch(getFavoritesSellers())
    dispatch(request(!params.offset ? { data: [] } : { data: sellersSearch }))

    const clientRequest = isSandbox ? dashBFFRequest : queryRequestsAPI

    clientRequest
      .get(urlRequest, { params })
      .then((response) => {
        const { data } = response

        if (data) {
          const { items: sellers, total, limit } = data
          const totalPages = Math.ceil(total / limit) || 1
          dispatch(success(sellers, total, totalPages, limit))
        }
      })
      .catch((error) => {
        const { response } = error
        if (response?.status === 404) {
          return dispatch(success([], 0, 0, 0))
        }

        if (response?.status === 504 || response?.status === 500) {
          return dispatch(failure(takeError(error, sellersErrors)))
        }
        dispatch(failure(error.response))
      })
  }
}

// ACTION DUPLICADA PARA ATENDER A PESQUISA NO SELECT DE SELLERS E NÃO INFLUENCIAR NA LISTA DE SELLERS
export const searchSellerSelect = (filters = {}) => {
  const request = () => ({ type: GET_SELLERS_SEARCH_REQUEST })
  const success = (data, sellersSearchCount, totalPages, limit) => ({
    type: GET_SELLERS_SEARCH_SUCCESS,
    data,
    sellersSearchCount,
    totalPages,
    limit
  })
  const failure = (error) => ({ type: GET_SELLERS_SEARCH_ERROR, error })
  return (dispatch, getState) => {
    if (!filters.name) {
      delete filters['name']
    }

    const { marketplace: rootMarketplace } = getState()

    const { mountedParams, isSandbox } = checkSandbox(rootMarketplace)

    const params = {
      ...defaultParamsSelect,
      ...filters,
      sort: mountedParams.sort || 'name:asc'
    }

    const clientRequest = isSandbox ? dashBFFRequest : queryRequestsAPI

    const requestSearchSeller = clientRequest.get(uriRequests.searchSeller, {
      params
    })

    dispatch(request())

    requestSearchSeller
      .then((response) => {
        const { data } = response

        if (data) {
          const { items: sellers, total, limit } = data
          const totalPages = Math.ceil(total / limit) || 1
          dispatch(success(sellers, total, totalPages, limit))
        }
      })
      .catch((error) => {
        const { response } = error

        if (response.status === 404) {
          return dispatch(success([], 0, 0, 0))
        }

        if (response.status === 504 || response.status === 500) {
          return dispatch(failure(takeError(error, sellersErrors)))
        }

        dispatch(failure(takeError(error, sellersErrors)))
      })
  }
}

// ACTION DUPLICADA PARA ATENDER A VERIFICAÇÃO DE DOCUMENTO E AJUSTAR A LÓGICA DE ERRO E SUCESSO (INVERTIDA)
// COM PROMISE PARA SER CHAMADA NA CHECAGEM DO FORM E VALIDAR QUE O DOCUMENTO/EMAIL NÃO ESTÁ NA BASE
export const verifyDataSeller = (params) => {
  const requestSearchSeller = queryRequestsAPI.get(uriRequests.searchSeller, {
    params
  })

  return () => {
    return new Promise((resolve) => {
      requestSearchSeller
        .then((response) => {
          const { items } = response.data

          const isDeleted = params?.checkDeletedUser
            ? items?.every((item) => item.status === 'deleted')
            : false
          const exist = items && items.length > 0 && !isDeleted

          resolve(exist)
        })
        .catch((error) => {
          const { response } = error
          if (response.status === 404) {
            return resolve(false)
          }

          if (response.status === 504 || response.status === 500) {
            return resolve(false)
          }
          resolve(false)
        })
    })
  }
}

export const selectSellerById = (sellerId, redirect) => {
  const request = () => ({ type: SELLER_DETAILS_REQUEST })
  const success = (data) => ({
    type: SELLER_DETAILS_SUCCESS,
    data,
    seller: data,
    redirect
  })
  const failure = (error) => ({ type: SELLER_DETAILS_ERROR, error })

  const getSellerDetailsRequest = queryRequestsAPI.get(
    uriRequests.getSellerDetails.replace('{sellerId}', sellerId)
  )

  return (dispatch) => {
    dispatch(request())

    getSellerDetailsRequest
      .then((response) => {
        dispatch(success(response.data))
      })
      .catch((error) => {
        const { response } = error

        if (response.status === 404) {
          return dispatch(success([]))
        }

        if (response.status === 504 || response.status === 500) {
          return dispatch(failure(takeError(error, sellersErrors)))
        }

        dispatch(failure(takeError(error, sellersErrors)))
      })
  }
}
