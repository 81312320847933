import requestApi from '../Configs/request'
import uriRequests from '../Configs/uriRequests'
import { getPanelSettings } from 'src/Helpers/Settings'
import {
  API_SETTINGS_REQUEST,
  API_SETTINGS_SUCCESS,
  API_SETTINGS_ERROR
} from '../Constants/ActionTypes'

export const getApiSettings = (disableLoading) => {
  const request = () => { return { type: API_SETTINGS_REQUEST, disableLoading } }
  const success = (settings) => { return { type: API_SETTINGS_SUCCESS, settings } }
  const failure = (error) => { return { type: API_SETTINGS_ERROR, error } }

  return (dispatch) => {
    dispatch(request())

    const urlRequest = uriRequests.getApiSettings
    requestApi.get(urlRequest, {
      params: {
        limit: 100
      }
    }).then((response) => {
      const { data: { items: settings } } = response
      const panelSettings = getPanelSettings(settings)

      dispatch(success(panelSettings))
    })
      .catch(() => {
        dispatch(failure('Não foi possível carregar as configurações.'))
      })
  }
}
