import {
  ADD_WEBHOOKS_ERROR,
  ADD_WEBHOOKS_REQUEST,
  ADD_WEBHOOKS_SUCCESS,
  GET_WEBHOOKS_ERROR,
  GET_WEBHOOKS_REQUEST,
  GET_WEBHOOKS_SUCCESS,
  GET_WEBHOOKS_EVENTS_REQUEST,
  GET_WEBHOOKS_EVENTS_SUCCESS,
  GET_WEBHOOKS_EVENTS_ERROR,
  REMOVE_WEBHOOK_ERROR,
  REMOVE_WEBHOOK_REQUEST,
  REMOVE_WEBHOOK_SUCCESS,
  RESET_WEBHOOK
} from '../Constants/ActionTypes'

import createToast from '../Utils/createToast'
import requestApi from '../Configs/request'
import takeError from '../Errors/takeError'
import uriRequests from '../Configs/uriRequests'
import webhooksErrors from '../Errors/webhooksErros'
import dashBFFRequestApi from '../Configs/dashBFFRequest'

export const getWebhooks = (limit = 20) => {
  const request = () => ({ type: GET_WEBHOOKS_REQUEST })
  const success = (data, totalPages) => ({
    type: GET_WEBHOOKS_SUCCESS,
    data,
    totalPages
  })
  const failure = (error) => ({ type: GET_WEBHOOKS_ERROR, error })

  const requestWebhooks = requestApi.get(uriRequests.getWebhooks)

  return (dispatch) => {
    dispatch(request())

    requestWebhooks
      .then((response) => {
        const numberOfPages = Math.ceil(response.data.items.length / limit) || 1
        dispatch(success(response.data.items, numberOfPages))
      })
      .catch((error) => dispatch(failure(error)))
  }
}

export const getWebhooksEvents = () => {
  const request = () => ({ type: GET_WEBHOOKS_EVENTS_REQUEST })
  const success = (data) => ({ type: GET_WEBHOOKS_EVENTS_SUCCESS, data })
  const failure = (error) => ({ type: GET_WEBHOOKS_EVENTS_ERROR, error })

  const requestWebhooksEvents = requestApi.get(uriRequests.getWebhooksEvents, {
    ignoreSellerAndMarketplaceContext: true
  })

  return (dispatch) => {
    dispatch(request())

    requestWebhooksEvents
      .then((response) => {
        dispatch(success(response.data))
      })
      .catch((error) => {
        dispatch(failure(error))
      })
  }
}

export const addWebhooks = (marketplaceId, data) => {
  const request = () => ({ type: ADD_WEBHOOKS_REQUEST })
  const success = () => ({ type: ADD_WEBHOOKS_SUCCESS })
  const failure = (error) => ({ type: ADD_WEBHOOKS_ERROR, error })

  const requestWebhooks = dashBFFRequestApi.post(uriRequests.addWebhooks, data)

  return async (dispatch) => {
    dispatch(request())

    requestWebhooks
      .then((response) => {
        createToast(
          'success',
          'Sucesso! O Webhook foi criado.',
          response.data.id
        )
        dispatch(success())
        dispatch(getWebhooks(marketplaceId))
      })
      .catch((error) => {
        const e = takeError(error, webhooksErrors)
        createToast(
          'error',
          `Não conseguimos <strong>criar</strong> o Webhook.
                                             Tente novamente | ERRO <strong>${e.message}</strong>`,
          data.method
        )
        dispatch(failure(e))
      })
  }
}

export const removeWebhook = (marketplaceId, webhookId) => {
  const request = () => ({ type: REMOVE_WEBHOOK_REQUEST })
  const success = () => ({ type: REMOVE_WEBHOOK_SUCCESS })
  const failure = (error) => ({ type: REMOVE_WEBHOOK_ERROR, error })

  const requestWebhooks = requestApi.delete(
    uriRequests.deleteWebhook.replace('{webhookId}', webhookId)
  )

  return (dispatch) => {
    dispatch(request())

    requestWebhooks
      .then(() => {
        dispatch(success())
        dispatch(getWebhooks(marketplaceId))
        createToast('success', 'Sucesso! O Webhook foi excluído.', webhookId)
      })
      .catch((error) => {
        const e = takeError(error, webhooksErrors)
        createToast(
          'error',
          `Não conseguimos <strong>excluir</strong> o Webhook.
                                                Tente novamente | ERRO <strong>${e.message}</strong>`,
          webhookId
        )
        dispatch(failure(e))
      })
  }
}

export const resetWebhooks = () => (dispatch) =>
  dispatch({ type: RESET_WEBHOOK })
