import { RESET_UPLOAD_DOCUMENTS } from 'src/Constants/ActionTypes'
import {
  isAccountDataCompleted,
  isBankAccountsDataCompleted,
  isDocumentsDataCompleted
} from 'src/Helpers/Register'
import dashBFFRequest from '../Configs/dashBFFRequest'
import queryRequestsAPI from '../Configs/queryRequestsAPI'
import requestApi from '../Configs/request'
import uriRequests from '../Configs/uriRequests'
import {
  CREATE_SELLER_ERROR,
  CREATE_SELLER_REQUEST,
  CREATE_SELLER_RESET,
  CREATE_SELLER_SUCCESS,
  DELETE_SELLER_ERROR,
  DELETE_SELLER_REQUEST,
  DELETE_SELLER_SUCCESS,
  FINAL_CREATE_SELLER_SUCCESS,
  GET_SELLER_DOCUMENTS_ERROR,
  GET_SELLER_DOCUMENTS_REQUEST,
  GET_SELLER_DOCUMENTS_SUCCESS,
  INVITE_USER_ERROR,
  INVITE_USER_REQUEST,
  INVITE_USER_SUCCESS,
  REGISTER_STATUS_ERROR,
  REGISTER_STATUS_REQUEST,
  REGISTER_STATUS_SUCCESS,
  SELLER_DETAILS_RESET,
  UPDATE_SELLER_DETAILS_ERROR,
  UPDATE_SELLER_DETAILS_REQUEST,
  UPDATE_SELLER_DETAILS_SUCCESS,
  UPDATE_SELLER_OWNER_DETAILS_ERROR,
  UPDATE_SELLER_OWNER_DETAILS_REQUEST,
  UPDATE_SELLER_OWNER_DETAILS_SUCCESS,
  UPLOAD_SELLER_DOCUMENT_ERROR,
  UPLOAD_SELLER_DOCUMENT_REQUEST,
  UPLOAD_SELLER_DOCUMENT_SUCCESS
} from '../Constants/ActionTypes'
import sellerErrors from '../Errors/sellerErrors'
import takeError from '../Errors/takeError'
import createToast from '../Utils/createToast'
import { createBankAccountToken, updateReceivingPolicy } from './bankAccounts'

export const getSeller = async (id) => {
  let response = null
  const uri = uriRequests.getSellerDetails.replace('{sellerId}', id)
  const { data } = await dashBFFRequest.get(uri)
  const primaryBankingAccountId = await getPrimaryHolderAccountId(id)

  if (data) {
    response = data
  }

  if (primaryBankingAccountId) {
    response = {
      ...response,
      primaryBankingAccountId
    }
  }

  return response
}

export const getSellerByDocument = async (document) => {
  const urlRequest = `${uriRequests.searchSeller}`

  return queryRequestsAPI
    .get(urlRequest, {
      apiVersion: '1',
      params: { not_status: 'deleted', ...document }
    })
    .then((response) => {
      if (response.data) {
        return response.data.items
      }
    })
    .catch(() => {
      return null
    })
}

export const updateBusiness = (
  type,
  businessId,
  params,
  sellerUpdate = false
) => {
  const request = () => ({
    type: sellerUpdate
      ? UPDATE_SELLER_DETAILS_REQUEST
      : UPDATE_SELLER_OWNER_DETAILS_REQUEST
  })
  const success = (seller) => ({
    type: sellerUpdate
      ? UPDATE_SELLER_DETAILS_SUCCESS
      : UPDATE_SELLER_OWNER_DETAILS_SUCCESS,
    seller
  })
  const failure = (error) => ({
    type: sellerUpdate
      ? UPDATE_SELLER_DETAILS_ERROR
      : UPDATE_SELLER_OWNER_DETAILS_ERROR,
    error
  })

  return (dispatch, getState) => {
    dispatch(request())
    const {
      marketplace: {
        details: { id: marketplaceId }
      }
    } = getState()

    const updateBusinessRequest = dashBFFRequest.put(
      uriRequests.updateBusiness
        .replace(
          '{businessType}',
          type === 'business' ? 'businesses' : 'individuals'
        )
        .replace('{marketplaceId}', marketplaceId)
        .replace('{businessId}', businessId),
      { ...params }
    )

    updateBusinessRequest
      .then((response) => {
        if (sellerUpdate) {
          createToast(
            'success',
            'Sucesso! As <strong>alterações</strong> foram salvas',
            response.data.id
          )
        } else {
          createToast(
            'success',
            'Sucesso! Seu cadastro agora está atualizado.',
            response.data.id
          )
        }
        dispatch(success(response.data))
      })
      .catch((error) => {
        const e = takeError(error, sellerErrors)
        if (e.status === 500) {
          if (sellerUpdate) {
            createToast(
              'error',
              'Erro ao tentar salvar as <strong>alterações</strong>. Tente novamente',
              businessId,
              e.status
            )
          } else {
            createToast(
              'error',
              'Não conseguimos atualizar seus dados cadastrais. Tente novamente',
              businessId,
              e.status
            )
          }
        } else {
          createToast('error', e.message, businessId, e.status)
        }
        dispatch(failure(e))
      })
  }
}

export const getPrimaryHolderAccountId = async (sellerId) => {
  try {
    const urlRequest = uriRequests.getHolderAccounts.replace(
      '{sellerId}',
      sellerId
    )
    const { data } = await requestApi.get(urlRequest)
    if (data) {
      const { items } = data
      const primaryAccount = items?.find((item) => item.primary)
      if (primaryAccount) {
        const { id } = primaryAccount
        return id
      }
      return null
    }
  } catch (e) {
    return null
  }
}

export const createSeller = (
  marketplaceId,
  data,
  invite,
  banking,
  received
) => {
  const request = () => ({ type: CREATE_SELLER_REQUEST })
  const success = (seller) => ({ type: CREATE_SELLER_SUCCESS, seller })
  const failure = (error) => ({ type: CREATE_SELLER_ERROR, error })

  return async (dispatch, getState) => {
    dispatch(request())
    const urlRequest = uriRequests.createSeller

    let sellerId

    try {
      const res = await dashBFFRequest.post(urlRequest, data, {
        headers: {
          'Content-Type': 'application/json'
        },
        params: {
          invite,
          application: 'panel'
        }
      })

      sellerId = res.data.id
      dispatch(success(res.data))
      if (!banking) {
        dispatch(finalCreateSeller())
      }
      if (sellerId && banking) {
        await dispatch(
          createBankAccountToken(marketplaceId, sellerId, banking, received)
        )
        const { associateBankAccountSuccess } = await getState().recipientMeans
        const { createBankAccountSuccess } = await getState().bankAccount

        if (createBankAccountSuccess && associateBankAccountSuccess) {
          if (received) {
            await dispatch(updateReceivingPolicy(received, sellerId, true))
          }
          dispatch(finalCreateSeller())
        }
      }
    } catch (e) {
      dispatch(failure(takeError(e, sellerErrors)))
    }
  }
}

export const finalCreateSeller = () => (dispatch) => {
  dispatch({ type: FINAL_CREATE_SELLER_SUCCESS })
}

export const resetCreateSeller = () => (dispatch) => {
  dispatch({ type: CREATE_SELLER_RESET })
}

export const getRegisterStatus = (marketplaceId, sellerId) => {
  const request = () => ({ type: REGISTER_STATUS_REQUEST })
  const success = (
    accountDataCompleted,
    documentsDataCompleted,
    bankAccountsDataCompleted
  ) => ({
    type: REGISTER_STATUS_SUCCESS,
    accountDataCompleted,
    documentsDataCompleted,
    bankAccountsDataCompleted
  })
  const failure = (error) => ({ type: REGISTER_STATUS_ERROR, error })

  return async (dispatch) => {
    dispatch(request())

    try {
      const accountDataResponse = await requestApi.get(
        uriRequests.getSellerDetails.replace('{sellerId}', sellerId)
      )
      const accountDataCompleted = isAccountDataCompleted(
        accountDataResponse.data
      )
      const documentsDataResponse = await requestApi.get(
        uriRequests.getDocuments.replace('{sellerId}', sellerId)
      )
      const documentsDataCompleted = isDocumentsDataCompleted(
        documentsDataResponse.data && documentsDataResponse.data.items
      )
      const bankAccountsDataResponse = await requestApi.get(
        uriRequests.listBankAccounts.replace('{sellerId}', sellerId)
      )
      const bankAccountsDataCompleted = isBankAccountsDataCompleted(
        bankAccountsDataResponse.data && bankAccountsDataResponse.data.items
      )

      dispatch(
        success(
          accountDataCompleted,
          documentsDataCompleted,
          bankAccountsDataCompleted
        )
      )
    } catch (e) {
      dispatch(failure(e.response))
    }
  }
}

export const inviteUser = (
  marketplaceId,
  userData,
  profile,
  customerId,
  application = 'dash'
) => {
  const request = () => ({ type: INVITE_USER_REQUEST })
  const success = (data) => ({ type: INVITE_USER_SUCCESS, data })
  const failure = (error) => ({ type: INVITE_USER_ERROR, error })

  let permissions = [
    {
      type: 'model',
      model_name: profile,
      customer_id: customerId || '*'
    }
  ]

  return (dispatch) => {
    const params = { ...userData, permissions }
    const requestUser = dashBFFRequest.post(
      `${uriRequests.inviteUser}&application=${application}`,
      { ...params }
    )

    dispatch(request())

    requestUser
      .then((response) => {
        createToast(
          'success',
          'Sucesso! Enviamos um <strong>convite</strong> para o usuário acessar o marketplace.',
          response.data.id
        )
        dispatch(success(response.data))
      })
      .catch((error) => {
        const e = takeError(error, sellerErrors)
        if (e.status === 500 || e.status === 404) {
          createToast(
            'error',
            'Não conseguimos <strong>convidar este usuário</strong>. Tente novamente.',
            customerId,
            e.status
          )
        } else {
          createToast('error', e.message, customerId, e.status)
        }
        dispatch(failure(e))
      })
  }
}

export const removeSeller = (sellerId) => {
  const request = () => ({ type: DELETE_SELLER_REQUEST })
  const success = () => ({ type: DELETE_SELLER_SUCCESS })
  const failure = (error) => ({ type: DELETE_SELLER_ERROR, error })

  return (dispatch, getState) => {
    dispatch(request())
    const {
      marketplace: {
        details: { id: marketplaceId }
      }
    } = getState()

    const deleteSellerRequest = dashBFFRequest.delete(
      uriRequests.deleteSeller
        .replace('{marketplaceId}', marketplaceId)
        .replace('{sellerId}', sellerId)
    )

    deleteSellerRequest
      .then((response) => {
        createToast(
          'success',
          'Sucesso! O estabelecimento foi <strong>excluido!</strong>',
          response.data.id
        )
        dispatch(success())
      })
      .catch((error) => {
        const e = takeError(error, sellerErrors)
        createToast('error', null, sellerId, e.status)
        dispatch(failure(e))
      })
  }
}

export const getSellerDocument = (sellerId) => {
  const request = () => ({ type: GET_SELLER_DOCUMENTS_REQUEST })
  const success = (data) => ({ type: GET_SELLER_DOCUMENTS_SUCCESS, data })
  const failure = (error) => ({ type: GET_SELLER_DOCUMENTS_ERROR, error })

  return (dispatch) => {
    dispatch(request())

    const getSellerDocumentRequest = requestApi.get(
      uriRequests.getSellerDocuments.replace('{sellerId}', sellerId)
    )

    getSellerDocumentRequest
      .then(({ data: { items } }) => {
        dispatch(success(items))
      })
      .catch((error) => {
        const e = takeError(error, sellerErrors)
        dispatch(failure(e))
      })
  }
}

export const downloadSellerDocument = (documentId, filename) => {
  return () => {
    const uri = uriRequests.downloadSellerDocument.replace(
      '{documentId}',
      documentId
    )

    return requestApi
      .get(uri, {
        responseType: 'arraybuffer',
        headers: {
          Accept: 'binary/octet-stream'
        }
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click()
        link.remove()
      })
      .catch((error) => {
        const e = takeError(error, sellerErrors)
        if (e.status === 500) {
          e.message =
            'Não conseguimos fazer o download do documento. Por favor, tente novamente.'
        }
        createToast('error', e.message, '', e.status)
        throw error
      })
  }
}

export const uploadSellerDocument = (sellerId, file, category) => {
  const request = () => ({ type: UPLOAD_SELLER_DOCUMENT_REQUEST })
  const success = (newDocument) => ({
    type: UPLOAD_SELLER_DOCUMENT_SUCCESS,
    newDocument
  })
  const failure = (error) => ({ type: UPLOAD_SELLER_DOCUMENT_ERROR, error })

  return (dispatch) => {
    dispatch(request())

    let fd = new FormData()
    fd.append('file', file)

    const uploadSellerDocumentRequest = dashBFFRequest.post(
      uriRequests.uploadSellerDocument.replace('{sellerId}', sellerId),
      fd,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        params: {
          category
        }
      }
    )

    return uploadSellerDocumentRequest
      .then(({ data: newDocument }) => {
        dispatch(success(newDocument))
        createToast(
          'success',
          `<strong>Documento</strong> enviado com sucesso.`
        )
      })
      .catch((error) => {
        const e = takeError(error, sellerErrors)
        dispatch(failure(e))
        if (e.status === 500) {
          e.message =
            'Não conseguimos enviar o documento. Por favor, tente novamente.'
        }
        createToast('error', e.message, sellerId, e.status)
        throw error
      })
  }
}

export const getBuyer = async (customerId) => {
  const urlRequest = uriRequests.getBuyer.replace('{buyerId}', customerId)

  return requestApi
    .get(urlRequest)
    .then((response) => {
      if (response.data) {
        return response.data
      } else {
        return null
      }
    })
    .catch(() => {
      return null
    })
}

export const resetUploadSellerDocument = () => (dispatch) =>
  dispatch({ type: RESET_UPLOAD_DOCUMENTS })

export const resetSellerDetail = () => (dispatch) =>
  dispatch({ type: SELLER_DETAILS_RESET })
