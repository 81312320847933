import dashBFFRequestApi from '../Configs/dashBFFRequest'
import requestApi from '../Configs/request'
import uriRequests from '../Configs/uriRequests'

import {
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
  UPDATE_PASS_REQUEST,
  UPDATE_PASS_SUCCESS,
  UPDATE_PASS_ERROR,
  RESET_USER,
  LIST_USERS_REQUEST,
  LIST_USERS_SUCCESS,
  LIST_USERS_ERROR,
  DELETE_USER_PROFILE_REQUEST,
  DELETE_USER_PROFILE_SUCCESS,
  DELETE_USER_PROFILE_ERROR,
  RESET_MANAGER_USER,
  UPDATE_PERMISSION_REQUEST,
  UPDATE_PERMISSION_SUCCESS,
  UPDATE_PERMISSION_ERROR,
  USER_DETAIL_REQUEST,
  USER_DETAIL_SUCCESS,
  USER_DETAIL_ERROR,
  RESET_UPDATE_USER_PASS
} from '../Constants/ActionTypes'
import takeError from '../Errors/takeError'
import userErrors from '../Errors/userErrors'
import createToast from '../Utils/createToast'

export const updateUser = (userId, profile, token) => {
  const request = () => ({ type: UPDATE_USER_REQUEST })
  const success = (userDetail) => ({ type: UPDATE_USER_SUCCESS, userDetail })
  const failure = (error) => ({ type: UPDATE_USER_ERROR, error })

  return (dispatch) => {
    const requestUser = dashBFFRequestApi.patch(
      uriRequests.updateUser.replace('{userid}', userId),
      { profile },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    )

    dispatch(request())

    requestUser
      .then((response) => {
        dispatch(success(response.data))
      })
      .catch((error) => {
        dispatch(failure(takeError(error, userErrors)))
      })
  }
}

export const updateLoggedInUser = (userId, userData) => {
  const request = () => ({ type: UPDATE_USER_REQUEST })
  const success = (firstName, lastName) => ({
    type: UPDATE_USER_SUCCESS,
    firstName,
    lastName
  })
  const failure = (error) => ({ type: UPDATE_USER_ERROR, error })

  const urlRequest = uriRequests.updateUser.replace('{userid}', userId)

  return async (dispatch) => {
    dispatch(request())

    try {
      const {
        data: {
          profile: { first_name: firstName, last_name: lastName }
        }
      } = await dashBFFRequestApi.patch(urlRequest, userData)

      dispatch(success(firstName, lastName))
      createToast(
        'success',
        'Sucesso! Seus dados pessoais estão \n' + 'atualizados agora.',
        userId
      )
    } catch (e) {
      const error = takeError(e, userErrors)
      createToast(
        'error',
        'Não conseguimos atualizar seus dados \n' +
          'pessoais. Tente novamente. | ERRO <strong>' +
          error.status +
          '</strong>',
        userId
      )
      dispatch(failure(error))
    }
  }
}

export const updateLoggedInUserPassword = (userId, passwordData) => {
  const request = () => ({ type: UPDATE_PASS_REQUEST })
  const success = () => ({ type: UPDATE_PASS_SUCCESS })
  const failure = (error) => ({ type: UPDATE_PASS_ERROR, error })

  const urlRequest = uriRequests.updatePass.replace('{userid}', userId)

  return async (dispatch) => {
    dispatch(request())

    try {
      await dashBFFRequestApi.post(urlRequest, passwordData)

      createToast(
        'success',
        'Sucesso! Sua <strong>senha</strong> foi alterada. ',
        userId
      )
      dispatch(success())
    } catch (e) {
      const error = takeError(e, userErrors)

      if (e?.response?.data?.error?.type === 'password_rules') {
        createToast('error', error.message, userId)
      } else {
        createToast(
          'error',
          'Não conseguimos alterar sua senha. \n' +
            `Tente novamente. | ERROR <strong>` +
            error.status +
            '</strong>',
          userId
        )
      }
      dispatch(failure(error))
    }
  }
}

export const updateUserPassword = (userId, passwordData, token) => {
  const request = () => ({ type: UPDATE_PASS_REQUEST })
  const success = (userId) => ({ type: UPDATE_PASS_SUCCESS, userId })
  const failure = (error) => ({ type: UPDATE_PASS_ERROR, error })

  return (dispatch) => {
    const requestUser = requestApi.post(
      uriRequests.updatePass.replace('{userid}', userId),
      passwordData,
      {
        ignoreSellerAndMarketplaceContext: true,
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    )

    dispatch(request())
    return (
      requestUser
        // eslint-disable-next-line no-unused-vars
        .then((response) => {
          dispatch(success(userId))
        })
        .catch((error) => {
          dispatch(failure(takeError(error, userErrors)))
        })
    )
  }
}

export const resetUser = () => {
  return (dispatch) => {
    dispatch({ type: RESET_USER })
  }
}

export const getUsersList = () => {
  const request = () => ({ type: LIST_USERS_REQUEST })
  const success = (usersList, totalPages) => ({
    type: LIST_USERS_SUCCESS,
    usersList,
    totalPages
  })
  const failure = (error) => ({ type: LIST_USERS_ERROR, error })

  return (dispatch) => {
    const requestUser = dashBFFRequestApi.get(uriRequests.userList)
    dispatch(request())

    requestUser
      .then((response) => {
        const items = response.data.items.filter(
          (item) => item.profile.dashboard_layout !== 'customers'
        )
        const itemsWithFullName = items.map((item) => {
          const existFirstName = item.profile && item.profile.first_name
          const existLastName = item.profile && item.profile.last_name
          item.fullName =
            existFirstName && existLastName
              ? `${item.profile.first_name} ${item.profile.last_name}`
              : existFirstName && !existLastName
                ? `${item.profile.first_name}`
                : !existFirstName && existLastName
                  ? `${item.profile.last_name}`
                  : ''
          return item
        })
        const numberOfPages = Math.ceil(items.length / 10) || 1
        dispatch(success(itemsWithFullName, numberOfPages))
      })
      .catch((error) => {
        dispatch(failure(takeError(error, userErrors)))
      })
  }
}

export const deletetUserProfile = (marketplaceId, userId) => {
  const request = () => {
    return { type: DELETE_USER_PROFILE_REQUEST }
  }
  const success = (userId) => {
    return { type: DELETE_USER_PROFILE_SUCCESS, userId }
  }
  const failure = (error) => {
    return { type: DELETE_USER_PROFILE_ERROR, error }
  }

  return (dispatch) => {
    const requestUser = dashBFFRequestApi.delete(
      uriRequests.delUser.replace('{userid}', userId)
    )

    dispatch(request())

    requestUser
      // eslint-disable-next-line no-unused-vars
      .then((response) => {
        dispatch(resetManagerUser())
        dispatch(getUsersList())
        dispatch(success(userId))
        createToast(
          'success',
          'Sucesso! O usuário foi <strong>excluído</strong> e não possui mais acesso ao marketplace.',
          userId
        )
      })
      .catch((error) => {
        const e = takeError(error, userErrors)
        if (e.status === 500) {
          createToast(
            'error',
            'Não conseguimos <strong>excluir este usuário</strong>. Tente novamente.',
            userId
          )
        } else {
          createToast('error', e.message, userId)
        }
        dispatch(failure(e))
      })
  }
}

export const updateUserProfile = (marketplaceId, userId, profile) => {
  const request = () => ({ type: UPDATE_PERMISSION_REQUEST, userId, profile })
  const success = (data) => ({ type: UPDATE_PERMISSION_SUCCESS, data })
  const failure = (error) => ({ type: UPDATE_PERMISSION_ERROR, error })

  return (dispatch) => {
    dispatch(request())
    const updateProfileUri = uriRequests.setProfile.replace('{userid}', userId)

    dashBFFRequestApi
      .post(updateProfileUri, {
        model_name: profile.value
      })
      .then(({ data }) => {
        dispatch(getUserDetailAndRole(marketplaceId, userId))
        dispatch(success(data))
        createToast(
          'success',
          `Sucesso! O perfil do usuário foi alterado para <strong>${profile.label.toLowerCase()}</strong>.`,
          userId
        )
      })
      .catch((error) => {
        const e = takeError(error, userErrors)
        if (e.status === 500) {
          createToast(
            'error',
            'Não conseguimos <strong>alterar o perfil</strong> do usuário. Tente novamente.',
            userId
          )
        } else {
          createToast('error', e.message, userId)
        }
        dispatch(failure(e))
      })
  }
}

export const getUserDetailAndRole = (marketplaceId, userId) => {
  const request = () => ({ type: USER_DETAIL_REQUEST })
  const success = (userDetail, totalPages) => ({
    type: USER_DETAIL_SUCCESS,
    userDetail,
    totalPages
  })
  const failure = (error) => ({ type: USER_DETAIL_ERROR, error })

  return (dispatch) => {
    dispatch(request())
    const requestUser = dashBFFRequestApi.get(
      uriRequests.userDetail.replace('{userid}', userId)
    )

    requestUser
      .then((response) => {
        // TODO Waiting for API implementation of pagination, so we don't need to check items length and give it a constant items limit per page
        const numberOfPages = response.data.items
          ? Math.ceil(response.data.items.length / 10)
          : 1
        dispatch(success(response.data, numberOfPages))
      })
      .catch((error) => {
        dispatch(failure(error.response))
        throw error
      })
  }
}

export const resetUserUpdatePass = () => (dispatch) =>
  dispatch({ type: RESET_UPDATE_USER_PASS })

export const resetManagerUser = () => {
  return (dispatch) => {
    dispatch({ type: RESET_MANAGER_USER })
  }
}
