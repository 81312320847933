import {
  CREATE_TRANSFER_ERROR,
  CREATE_TRANSFER_REQUEST,
  CREATE_TRANSFER_SUCCESS,
  GET_TRANSFER_BY_ID_ERROR,
  GET_TRANSFER_BY_ID_REQUEST,
  GET_TRANSFER_BY_ID_SUCCESS,
  GET_TRANSFER_HISTORY_ERROR,
  GET_TRANSFER_HISTORY_REQUEST,
  GET_TRANSFER_HISTORY_SUCCESS,
  GET_TRANSFER_RECIPIENT_ERROR,
  GET_TRANSFER_RECIPIENT_REQUEST,
  GET_TRANSFER_RECIPIENT_SUCCESS,
  GET_TRANSFER_SENDER_ERROR,
  GET_TRANSFER_SENDER_REQUEST,
  GET_TRANSFER_SENDER_SUCCESS,
  LIST_TRANSFERS_ERROR,
  LIST_TRANSFERS_REQUEST,
  LIST_TRANSFERS_SUCCESS,
  RESET_CREATE_TRANSFER
} from '../Constants/ActionTypes'

import moment from 'moment'
import queryRequestsAPI from 'src/Configs/queryRequestsAPI'
import takeError from 'src/Errors/takeError'
import transferErrors from 'src/Errors/transferErrors'
import { panelStore } from 'src/Store/panelStore'
import dashBFFRequestApi from '../Configs/dashBFFRequest'
import requestApi from '../Configs/request'
import uriRequests from '../Configs/uriRequests'

const SOURCE_FIELDS =
  'transfer_id,status_payment,confirmed,transfer_type,created_at,transfer_date,amount_payment,bank.holder_name,bank.bank_name,bank.routing_number,bank.routing_check_digit,bank.account_number'

const checkSandbox = (marketplace) => {
  if (
    marketplace?.details?.type === 'business_test' ||
    marketplace?.rootDetails?.type === 'business_test'
  ) {
    return {
      isSandbox: true,
      apiVersion: 1,
      mountedParams: {
        sort: 'time-descending',
        fields: null,
        not_type: null
      }
    }
  }
  return {
    isSandbox: false,
    apiVersion: 1,
    mountedParams: {
      sort: 'created_at:desc'
    }
  }
}

export const getTransfers = (filters = {}) => {
  const request = () => ({ type: LIST_TRANSFERS_REQUEST })
  const success = (transfers, totalItems, totalPages, limit) => ({
    type: LIST_TRANSFERS_SUCCESS,
    transfers,
    totalItems,
    totalPages,
    limit
  })
  const failure = (error) => ({ type: LIST_TRANSFERS_ERROR, error })

  if (filters.date_range && typeof filters.date_range === 'string') {
    filters.date_range = JSON.parse(filters.date_range)

    if (filters.date_range.gte) {
      filters.date_range.gte = moment(filters.date_range.gte).format(
        'DD/MM/YYYYTHH:mm:ss'
      )
    }

    if (filters.date_range.lte) {
      filters.date_range.lte = moment(filters.date_range.lte).format(
        'DD/MM/YYYYTHH:mm:ss'
      )
    }
  }

  if (filters.amount_range && typeof filters.amount_range === 'string') {
    filters.amount_range = JSON.parse(filters.amount_range)
  }

  if (filters.status) {
    if (filters.status === 'pending_bank') {
      filters.status = 'succeeded'
      filters.confirmed = 0
      filters.not_type = 'cash_in'
    } else if (
      filters.status.includes('pending_bank') &&
      !filters.status.includes('succeeded')
    ) {
      filters.status = filters.status.replace('pending_bank', '')
      filters.status += ',succeeded'
      filters.confirmed = 0
    } else if (
      filters.status.includes('pending_bank') &&
      filters.status.includes('succeeded')
    ) {
      filters.status = filters.status.replace('pending_bank', '')
    } else if (
      !filters.status.includes('pending_bank') &&
      filters.status.includes('succeeded')
    ) {
      filters.confirmed = 1
    }
  }

  if (filters.type) {
    filters.type = filters.type.replace(/\|/g, ',')
  }

  if (filters.sort) {
    filters.sort = filters.sort.replace('date:', 'created_at:')
  }

  const { marketplace: rootMarketplace } = panelStore.getState()

  const { isSandbox, mountedParams } = checkSandbox(rootMarketplace)

  const params = {
    offset: 0,
    limit: 100,
    collapse: false,
    fields: SOURCE_FIELDS,
    not_type: 'payout_zoop,first_cash_in,cash_in,adjustment',
    date_range: {
      gte: moment().startOf('day').toDate(),
      lte: moment().endOf('day').toDate()
    },
    sort: mountedParams.sort,
    ...filters
  }

  if (isSandbox) {
    delete params.status
    delete params.type
    delete params.not_type
    delete params.fields

    if (
      typeof params.date_range.lte !== 'number' &&
      typeof params.date_range.gte !== 'number'
    ) {
      params.date_range.lte = moment(params.date_range.lte).unix()
      params.date_range.gte = moment(params.date_range.gte).unix()
    }

    params.sort =
      params.sort === 'created_at:desc' ? 'time-descending' : 'time-ascending'
  }

  if (filters.transfer_id) {
    delete params.date_range
  }

  return async (dispatch) => {
    const urlRequest = uriRequests.getMarketplaceTransfers

    dispatch(request())

    const clientRequest = isSandbox ? requestApi : queryRequestsAPI

    await clientRequest
      .get(urlRequest, { params })
      .then((response) => {
        const { data } = response

        if (data) {
          const { items, total, limit } = data
          const transfers = items.map((item) => {
            let { status, confirmed, type } = item
            switch (type) {
              case 'cash_out_automatic':
                type = 'payout_automatic'
                break
              case 'cash_out':
              case 'transfer_and_payout':
                type = 'payout_manual'
                break
            }
            if (
              status === 'succeeded' &&
              confirmed === '0' &&
              type !== 'cash_in'
            ) {
              status = 'pending_bank'
            }
            return {
              ...item,
              type,
              status,
              date: {
                day: moment(item.created_at).format('DD/MM/YYYY'),
                hour: moment(item.created_at).format('HH:mm:ss')
              }
            }
          })
          const totalPages = Math.ceil(total / limit) || 1
          dispatch(success(transfers, total, totalPages, limit))
        }
      })
      .catch((error) => {
        const { response } = error
        if (response.status === 404) {
          return dispatch(success([], 0, 0, params.limit))
        }

        dispatch(failure(takeError(error, transferErrors)))
      })
  }
}

export const getTransferSearchById = (transferId) => {
  const request = () => ({ type: LIST_TRANSFERS_REQUEST })
  const success = (transfers, totalItems, totalPages, limit) => ({
    type: LIST_TRANSFERS_SUCCESS,
    transfers,
    totalItems,
    totalPages,
    limit
  })
  const failure = (error) => ({ type: LIST_TRANSFERS_ERROR, error })

  return async (dispatch, getState) => {
    const {
      transfers: { transfersPageSize: limit }
    } = getState()

    const params = {
      sort: 'created_at:desc',
      offset: 0,
      limit: 100,
      collapse: false,
      not_type: 'payout_zoop,first_cash_in',
      fields: SOURCE_FIELDS
    }

    const urlRequest = uriRequests.getTransferDetails.replace(
      '{transferId}',
      transferId
    )

    dispatch(request())

    return queryRequestsAPI
      .get(urlRequest, { params })
      .then((response) => {
        const { data } = response

        if (data) {
          const items = [data]
          const total = 1
          const totalPages = 1
          dispatch(success(items, total.toString(), totalPages, limit))
        }
      })
      .catch((error) => {
        const { response } = error
        if (response.status === 404) {
          return dispatch(success([], '0', 0, 0))
        }

        dispatch(failure(takeError(error, transferErrors)))
      })
  }
}

export const getTransferDetail = (
  transferId,
  historyLimit,
  isAdjustment = false
) => {
  const request = () => ({ type: GET_TRANSFER_BY_ID_REQUEST })
  const success = (transfer) => ({ type: GET_TRANSFER_BY_ID_SUCCESS, transfer })
  const failure = (error) => ({ type: GET_TRANSFER_BY_ID_ERROR, error })

  return async (dispatch) => {
    const urlRequest = (
      isAdjustment
        ? uriRequests.getTransferAdjustmentsDetails
        : uriRequests.getTransferDetails
    ).replace('{transferId}', transferId)

    dispatch(request())

    try {
      let { data } = await requestApi.get(urlRequest)

      let {
        bank_account: bankAccount,
        sender,
        recipient,
        status,
        confirmed,
        type
      } = data

      switch (type) {
        case 'cash_out_automatic':
          type = 'payout_automatic'
          break
        case 'cash_out':
        case 'transfer_and_payout':
          type = 'payout_manual'
          break
      }

      if (status === 'succeeded' && confirmed === '0' && type !== 'cash_in') {
        status = 'pending_bank'
      }

      data = { ...data, status, type }

      const isPrepaidCard =
        data &&
        bankAccount &&
        bankAccount.bank_code &&
        bankAccount.bank_code === '10001'

      data.type = isPrepaidCard ? 'payout_prepaid_card' : data.type

      sender && (await dispatch(getTransferSenderDetails(sender)))
      recipient && (await dispatch(getTransferRecipientDetails(recipient)))

      if (['payout_automatic', 'cash_out_automatic'].includes(data.type)) {
        await dispatch(
          getTransferHistory(transferId, { limit: historyLimit, offset: 0 })
        )
      }

      dispatch(success(data))
    } catch (e) {
      dispatch(failure(takeError(e, transferErrors)))
    }
  }
}

export const getTransferSenderDetails = (senderId) => {
  const request = () => ({ type: GET_TRANSFER_SENDER_REQUEST })
  const success = (sender) => ({ type: GET_TRANSFER_SENDER_SUCCESS, sender })
  const failure = (error) => ({ type: GET_TRANSFER_SENDER_ERROR, error })

  const requestSender = uriRequests.getTransferSenderDetails.replace(
    '{senderId}',
    senderId
  )

  return async (dispatch) => {
    dispatch(request())

    try {
      const { data } = await dashBFFRequestApi.get(requestSender)

      dispatch(success(data))
    } catch (e) {
      dispatch(failure(takeError(e, transferErrors)))
    }
  }
}

export const getTransferRecipientDetails = (recipientId) => {
  const request = () => ({ type: GET_TRANSFER_RECIPIENT_REQUEST })
  const success = (recipient) => ({
    type: GET_TRANSFER_RECIPIENT_SUCCESS,
    recipient
  })
  const failure = (error) => ({ type: GET_TRANSFER_RECIPIENT_ERROR, error })

  const requestRecipient = uriRequests.getTransferRecipientDetails.replace(
    '{recipientId}',
    recipientId
  )

  return async (dispatch) => {
    dispatch(request())

    try {
      const { data } = await dashBFFRequestApi.get(requestRecipient)

      dispatch(success(data))
    } catch (e) {
      dispatch(failure(takeError(e, transferErrors)))
    }
  }
}

export const getTransferHistory = (transferId, params = { limit: 25 }) => {
  const request = (history) => ({ type: GET_TRANSFER_HISTORY_REQUEST, history })
  const success = ({ history, queryCount }) => ({
    type: GET_TRANSFER_HISTORY_SUCCESS,
    history,
    queryCount
  })
  const failure = (error) => ({ type: GET_TRANSFER_HISTORY_ERROR, error })

  return async (dispatch, getState) => {
    const {
      transfers: { transferHistory }
    } = getState()

    dispatch(request(!params.offset ? [] : transferHistory))

    const requestUrl = uriRequests.getTransferHistory.replace(
      '{transferId}',
      transferId
    )

    try {
      const {
        data: { items: history, query_count: queryCount }
      } = await requestApi.get(requestUrl, {
        params: {
          ...params
        }
      })

      dispatch(success({ history, queryCount }))
    } catch (e) {
      dispatch(failure(takeError(e, transferErrors)))
    }
  }
}

export const createP2PTransfer = (amount, description, destinySellerId) => {
  const request = () => ({ type: CREATE_TRANSFER_REQUEST })
  const success = (transfer) => ({ type: CREATE_TRANSFER_SUCCESS, transfer })
  const failure = (error) => ({ type: CREATE_TRANSFER_ERROR, error })

  return async (dispatch, getState) => {
    const {
      context: {
        seller: { id: sellerId }
      }
    } = getState()

    dispatch(request())

    const requestUrl = uriRequests.createP2PTransfer
      .replace('{originSellerId}', sellerId)
      .replace('{destinySellerId}', destinySellerId)

    try {
      const { data } = await dashBFFRequestApi.post(
        requestUrl,
        {
          amount,
          description
        },
        { apiVersion: '1' }
      )
      dispatch(success(data))
    } catch (e) {
      dispatch(failure(takeError(e, transferErrors)))
    }
  }
}

export const createBankAccountTransfer = (
  amount,
  description,
  bankAccountId
) => {
  const request = () => ({ type: CREATE_TRANSFER_REQUEST })
  const success = (transfer) => ({ type: CREATE_TRANSFER_SUCCESS, transfer })
  const failure = (error) => ({ type: CREATE_TRANSFER_ERROR, error })

  return async (dispatch) => {
    dispatch(request())

    const requestUrl = uriRequests.createBankAccountTransfer.replace(
      '{bankAccountId}',
      bankAccountId
    )

    try {
      const { data } = await dashBFFRequestApi.post(
        requestUrl,
        {
          amount,
          description,
          statement_descriptor: description
        },
        { apiVersion: '1' }
      )
      dispatch(success(data))
    } catch (e) {
      dispatch(failure(takeError(e, transferErrors)))
    }
  }
}

export const resetCreateTransfer = (justClearError = false) => {
  return (dispatch) => {
    dispatch({ type: RESET_CREATE_TRANSFER, justClearError })
  }
}
