import requestApi from '../Configs/request'
import uriRequests from '../Configs/uriRequests'
import takeError from 'src/Errors/takeError'

import {
  SEND_RECEIPT_REQUEST,
  SEND_RECEIPT_SUCCESS,
  SEND_RECEIPT_ERROR,
  GET_RECEIPT_REQUEST,
  GET_RECEIPT_SUCCESS,
  GET_RECEIPT_ERROR,
  RESET_RECEIPT,
  RESET_SEND_RECEIPT
} from '../Constants/ActionTypes'
import transactionErrors from 'src/Errors/transactionErrors'

export const sendReceipt = (receiptId, email) => {
  const request = () => ({ type: SEND_RECEIPT_REQUEST })
  const success = () => ({ type: SEND_RECEIPT_SUCCESS, email })
  const failure = error => ({ type: SEND_RECEIPT_ERROR, error })

  return (dispatch) => {
    const urlRequest = uriRequests.sendReceiptByEmail
      .replace('{receiptId}', receiptId)

    dispatch(request())

    return requestApi.post(urlRequest,
      {
        to: email
      },
      {
        headers: {
          'Content-type': 'application/json'
        }
      })
      .then(({ data }) => {
        if (data) {
          dispatch(success())
        }
      })
      .catch(e => {
        const error = takeError(e, transactionErrors)
        if (error.status === 500) {
          dispatch(failure({
            status: error.status,
            message: 'Não foi possível enviar o comprovante',
            error: e
          }))
        } else {
          dispatch(failure(error))
        }
      })
  }
}

export const getReceipt = (receiptId) => {
  const request = () => { return { type: GET_RECEIPT_REQUEST } }
  const success = (receipt) => { return { type: GET_RECEIPT_SUCCESS, receipt } }
  const failure = (error) => { return { type: GET_RECEIPT_ERROR, error } }

  return (dispatch) => {
    const urlRequest = uriRequests.getReceipts
      .replace('{receiptId}', receiptId)

    dispatch(request())

    return requestApi.get(urlRequest)
      .then(({ data }) => {
        if (data) {
          let response = {
            ...data
          }
          dispatch(success(response))
        }
      })
      .catch(error => {
        dispatch(failure(takeError(error, transactionErrors)))
      })
  }
}

export const resetReceipt = () => {
  const reset = () => ({ type: RESET_RECEIPT })

  return async (dispatch) => {
    dispatch(reset())
  }
}

export const resetSendReceipt = () => {
  const reset = () => ({ type: RESET_SEND_RECEIPT })

  return async (dispatch) => {
    dispatch(reset())
  }
}
