import dashBFFRequestApi from '../Configs/dashBFFRequest'
import requestApi from '../Configs/request'
import uriRequests from '../Configs/uriRequests'
import {
  GET_DEFAULT_BANK_RECEIVER_ERROR,
  GET_DEFAULT_BANK_RECEIVER_REQUEST,
  GET_DEFAULT_BANK_RECEIVER_SUCCESS,
  GET_RECEIVING_POLICY_ERROR,
  GET_RECEIVING_POLICY_REQUEST,
  GET_RECEIVING_POLICY_SUCCESS,
  RESET_RECIPIENT_MEANS,
  UPDATE_DEFAULT_CREDIT_ERROR,
  UPDATE_DEFAULT_CREDIT_REQUEST,
  UPDATE_DEFAULT_CREDIT_SUCCESS
} from '../Constants/ActionTypes'
import createToast from '../Utils/createToast'

export const setDefaultBankCredit = (bankAccountId, seller) => {
  const request = () => ({ type: UPDATE_DEFAULT_CREDIT_REQUEST })
  const success = (bankAccountId) => ({ type: UPDATE_DEFAULT_CREDIT_SUCCESS, bankAccountId })
  const failure = (error) => ({ type: UPDATE_DEFAULT_CREDIT_ERROR, error })

  return async (dispatch, getState) => {
    const {
      recipientMeans: {
        receivingPolicy: { transfer_enable: transferEnable, transfer_interval: transferInterval, minimum_transfer_value: minimumTransferValue }
      }
    } = getState()

    const { id: sellerId, type } = seller

    const updateReceivingPolicyURL = uriRequests.updateReceivingPolicy.replace('{sellerId}', sellerId)

    try {
      dispatch(request())

      await dashBFFRequestApi.post(updateReceivingPolicyURL, {
        seller: { type: type === 'business' ? 'businesses' : 'individuals' },
        default_credit: bankAccountId,
        transfer_enable: transferEnable,
        transfer_interval: transferInterval,
        minimum_transfer_value: minimumTransferValue
      })
      // }

      dispatch(success(bankAccountId))

      let message = 'Sucesso! Fique tranquilo, agora você está recebendo em sua <strong>Conta Bancária.</strong>'
      createToast('success', message)
    } catch (error) {
      dispatch(failure(error))
      createToast('error', 'Não conseguimos alterar o seu <strong>Meio de Recebimento.</strong> Tente novamente.')
    }
  }
}

export const getReceivingPolicy = (sellerId) => {
  const request = () => ({ type: GET_RECEIVING_POLICY_REQUEST })
  const success = (data) => ({ type: GET_RECEIVING_POLICY_SUCCESS, data })
  const failure = (error) => ({ type: GET_RECEIVING_POLICY_ERROR, error })
  return async (dispatch) => {
    const urlRequest = uriRequests.getReceivingPolicy.replace('{sellerId}', sellerId)
    try {
      dispatch(request())
      const { data } = await requestApi.get(urlRequest)
      dispatch(success(data))
    } catch (e) {
      dispatch(failure(e))
    }
  }
}

export const getDefaultBankReceiver = (sellerId) => {
  const request = () => ({ type: GET_DEFAULT_BANK_RECEIVER_REQUEST })
  const success = (bankAccountReceiver) => ({ type: GET_DEFAULT_BANK_RECEIVER_SUCCESS, bankAccountReceiver })
  const failure = (error) => ({ type: GET_DEFAULT_BANK_RECEIVER_ERROR, error })
  // eslint-disable-next-line no-unused-vars
  return async (dispatch, getState) => {
    const urlRequest = uriRequests.bankReceiver.replace('{sellerId}', sellerId)
    dispatch(request())
    try {
      const {
        data: { default_credit: bankAccountReceiver }
      } = await dashBFFRequestApi.get(urlRequest)
      dispatch(success(bankAccountReceiver))
    } catch (e) {
      dispatch(failure(e))
    }
  }
}

export const resetRecipientMeans = () => {
  const reset = () => ({ type: RESET_RECIPIENT_MEANS })

  return async (dispatch) => {
    dispatch(reset())
  }
}
