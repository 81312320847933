import { Auth } from 'aws-amplify'
import { MFA_ERROR, MFA_REQUEST, MFA_SUCCESS, RESET_MFA_ERROR } from '../Constants/ActionTypes'
import takeError from '../Errors/takeError'
import twofaErrors from '../Errors/twofaErrors'

export function signUp (email) {
  const failure = error => ({ type: MFA_ERROR, error })

  return async (dispatch) => {
    try {
      const params = {
        username: email,
        password: getRandomString(30),
        attributes: {
          email
        }
      }
      await Auth.signUp(params)
    } catch (e) {
      if (e.message !== 'User already exists') {
        dispatch(failure(takeError(e, twofaErrors)))
      }
    }
  }
}

function getRandomString (bytes) {
  const randomValues = new Uint8Array(bytes)
  window.crypto.getRandomValues(randomValues)
  return Array.from(randomValues).map(intToHex).join('@ZOOP')
}

function intToHex (nr) {
  return nr.toString(16).padStart(2, '0')
}

let cognitoUser
export function signIn (email) {
  const failure = error => ({ type: MFA_ERROR, error })

  return async (dispatch) => {
    try {
      cognitoUser = await Auth.signIn(email)
    } catch (e) {
      dispatch(failure(takeError(e, twofaErrors)))
    }
  }
}

export function answerCustomChallengeWithoutSession (answer) {
  const success = (sessionToken) => ({ type: MFA_SUCCESS, sessionToken })

  return async (dispatch) => {
    try {
      cognitoUser = await Auth.sendCustomChallengeAnswer(cognitoUser, answer)
      const session = await Auth.currentSession()
      if (session) {
        dispatch(success(session.accessToken?.jwtToken))
        return cognitoUser
      } else {
        return null
      }
    } catch (e) {
      return null
    }
  }
}

export function answerCustomChallenge (answer) {
  const request = () => ({ type: MFA_REQUEST })
  const success = (sessionToken) => ({ type: MFA_SUCCESS, sessionToken })
  const failure = error => ({ type: MFA_ERROR, error })

  return async (dispatch) => {
    dispatch(request())
    try {
      cognitoUser = await Auth.sendCustomChallengeAnswer(cognitoUser, answer)
      const session = await Auth.currentSession()
      if (session) {
        dispatch(success(session.accessToken?.jwtToken))
      } else {
        dispatch(failure(takeError({}, twofaErrors)))
      }
    } catch (e) {
      dispatch(failure(takeError({
        response: {
          data: {
            errorMessage: 'invalid_token'
          }
        }
      }, twofaErrors)))
    }
  }
}

export async function signOut () {
  try {
    await Auth.signOut()
  } catch {
    console.log('signout error')
  }
}

export function getCurrentSession () {
  const success = (sessionToken) => ({ type: MFA_SUCCESS, sessionToken })
  const failure = (error) => ({ type: MFA_ERROR, error })

  return async (dispatch) => {
    try {
      const currentSession = await Auth.currentSession()
      if (currentSession) {
        dispatch(success(currentSession.accessToken?.jwtToken))
      } else {
        dispatch(failure(takeError({}, twofaErrors)))
      }
    } catch (e) {
      dispatch(failure(takeError({}, twofaErrors)))
    }
  }
}

export function clearError () {
  return async (dispatch) => {
    dispatch({ type: RESET_MFA_ERROR })
  }
}
